var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.beatCartItems.length === 0
        ? [
            _c("icon", {
              staticClass: "cart__iconEmptyCart",
              attrs: { name: "cart-empty" },
            }),
            _c("CartHeading", {
              attrs: {
                title: "Your cart is empty :(",
                subtitle: "Add items to the cart to get started.",
              },
            }),
            _c("div", { staticClass: "cartNavigation" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn--lg btn--primary",
                  on: {
                    click: function ($event) {
                      return _vm.router.go(-1)
                    },
                  },
                },
                [_vm._v("RETURN TO SHOP")]
              ),
            ]),
          ]
        : [
            _c("CartHeading", {
              attrs: {
                title: "Review your order:",
                subtitle:
                  "Review your selection below and follow the next quick steps to complete your order.",
              },
            }),
            _vm.beatCartItems.length > 0
              ? _vm._l(_vm.beatCartItems, function (item) {
                  return _c("CartItemBeat", {
                    key: item.id,
                    attrs: { isCart: true, "cart-item": item },
                  })
                })
              : _vm._e(),
            _c("div", { staticClass: "cartNavigation" }, [
              _c("div", { staticClass: "cartNavigation__total" }, [
                _c("p", [
                  _vm._v("Your total is $" + _vm._s(_vm.cartTotalAmount)),
                ]),
                _c(
                  "a",
                  {
                    staticClass: "cartNavigation__coupon",
                    on: {
                      click: function ($event) {
                        return _vm.SET_ACTIVE_MODAL({
                          modal: _vm.ModalType.Coupon,
                        })
                      },
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          !_vm.coupons || _vm.coupons.length < 1
                            ? "Have a coupon code?"
                            : "Have another coupon code?"
                        )
                    ),
                  ]
                ),
              ]),
            ]),
            _c(
              "div",
              {
                staticClass:
                  "cartNavigation cartNavigation--btnFullWidthWrapper",
              },
              [
                _c(
                  "a",
                  {
                    staticClass: "btn btn--lg btn--darkGrey btn--border",
                    on: {
                      click: function ($event) {
                        return _vm.router.back()
                      },
                    },
                  },
                  [_vm._v("PREVIOUS")]
                ),
                _c(
                  "button",
                  {
                    staticClass: "btn btn--lg btn--secondary",
                    on: {
                      click: function ($event) {
                        return _vm.SET_CART_STEP(_vm.CartStep.PaymentOptions)
                      },
                    },
                  },
                  [_vm._v("NEXT STEP")]
                ),
              ]
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }